import { default as aboutlv6i1FL0CfMeta } from "/opt/build/repo/pages/about.vue?macro=true";
import { default as adsoffgDQwSyqvM6Meta } from "/opt/build/repo/pages/adsoff.vue?macro=true";
import { default as adtestz7oH2KNRmWMeta } from "/opt/build/repo/pages/adtest.vue?macro=true";
import { default as adtest2M6Sei4tNv5Meta } from "/opt/build/repo/pages/adtest2.vue?macro=true";
import { default as _91_91term_93_937oyaXUrpA5Meta } from "/opt/build/repo/pages/browse/[[term]].vue?macro=true";
import { default as _91_91id_93_93baAmgZz4KTMeta } from "/opt/build/repo/pages/chats/[[id]].vue?macro=true";
import { default as _91_91id_93_93cjIQB5RWmuMeta } from "/opt/build/repo/pages/chitchat/[[id]].vue?macro=true";
import { default as _91_91id_93_93eoW4CNWXHnMeta } from "/opt/build/repo/pages/communityevent/[[id]].vue?macro=true";
import { default as _91_91groupid_93_93Pcur6WyBETMeta } from "/opt/build/repo/pages/communityevents/[[groupid]].vue?macro=true";
import { default as disclaimercbAuCFHnKSMeta } from "/opt/build/repo/pages/disclaimer.vue?macro=true";
import { default as donatecFf5ywLGUZMeta } from "/opt/build/repo/pages/donate.vue?macro=true";
import { default as donatedjVJQSBNlM5Meta } from "/opt/build/repo/pages/donated.vue?macro=true";
import { default as engagehkTwYUaSGyMeta } from "/opt/build/repo/pages/engage.vue?macro=true";
import { default as essex2GLc59HcyeMeta } from "/opt/build/repo/pages/essex.vue?macro=true";
import { default as _91_91id_93_93ewUf9vt0pJMeta } from "/opt/build/repo/pages/explore/[[id]].vue?macro=true";
import { default as _91_91msgid_93_93UaZ3nzznJRMeta } from "/opt/build/repo/pages/explore/[groupid]/[[msgid]].vue?macro=true";
import { default as _91_91id_93_93q7kcga9opuMeta } from "/opt/build/repo/pages/explore/join/[[id]].vue?macro=true";
import { default as _91place_93p08uGA6eSiMeta } from "/opt/build/repo/pages/explore/place/[place].vue?macro=true";
import { default as _91region_9354uwiBXtsuMeta } from "/opt/build/repo/pages/explore/region/[region].vue?macro=true";
import { default as index6o45EBPOqPMeta } from "/opt/build/repo/pages/find/index.vue?macro=true";
import { default as whereamign8GiXPK77Meta } from "/opt/build/repo/pages/find/whereami.vue?macro=true";
import { default as whoamivJ9PYYFVrtMeta } from "/opt/build/repo/pages/find/whoami.vue?macro=true";
import { default as forgotUwPgolUkcoMeta } from "/opt/build/repo/pages/forgot.vue?macro=true";
import { default as giftaidTgb9chhRCmMeta } from "/opt/build/repo/pages/giftaid.vue?macro=true";
import { default as indexKVvMgLPmCHMeta } from "/opt/build/repo/pages/give/index.vue?macro=true";
import { default as whereamiPCoURtvCHRMeta } from "/opt/build/repo/pages/give/whereami.vue?macro=true";
import { default as whoamiZnFG1NKEDsMeta } from "/opt/build/repo/pages/give/whoami.vue?macro=true";
import { default as helpAA37UIVEuEMeta } from "/opt/build/repo/pages/help.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91id_93chQVASeUkCMeta } from "/opt/build/repo/pages/job/[id].vue?macro=true";
import { default as jobskGrf5d8on3Meta } from "/opt/build/repo/pages/jobs.vue?macro=true";
import { default as JustGivingThankYoutNZuSM5CWIMeta } from "/opt/build/repo/pages/JustGivingThankYou.vue?macro=true";
import { default as maintenance0n2g3CNJhCMeta } from "/opt/build/repo/pages/maintenance.vue?macro=true";
import { default as mergeFuNrIwWPplMeta } from "/opt/build/repo/pages/merge.vue?macro=true";
import { default as _91id_93xSQo07kXMDMeta } from "/opt/build/repo/pages/message/[id].vue?macro=true";
import { default as index_46client2MnN9RLjEeMeta } from "/opt/build/repo/pages/microvolunteering/index.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/opt/build/repo/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91_91id_93_93_46client1TT3cEYGDQMeta } from "/opt/build/repo/pages/microvolunteering/message/[[id]].client.vue?macro=true";
import { default as mobilez50oEGgk70Meta } from "/opt/build/repo/pages/mobile.vue?macro=true";
import { default as mydata78UEcNHtOtMeta } from "/opt/build/repo/pages/mydata.vue?macro=true";
import { default as _91_91action_93_93xf0GTG8bzGMeta } from "/opt/build/repo/pages/mypost/[id]/[[action]].vue?macro=true";
import { default as mypostsy8L0HsC1jEMeta } from "/opt/build/repo/pages/myposts.vue?macro=true";
import { default as NationalReuseDayCXQOusct1GMeta } from "/opt/build/repo/pages/NationalReuseDay.vue?macro=true";
import { default as _91_91id_93_93Dzj870aQu5Meta } from "/opt/build/repo/pages/noticeboards/[[id]].vue?macro=true";
import { default as _91_91id_93_93p6HcHl7If3Meta } from "/opt/build/repo/pages/noticeboards/authority/[[id]].vue?macro=true";
import { default as _91_91key_93_93esnXCuxdGlMeta } from "/opt/build/repo/pages/one-click-unsubscribe/[[uid]]/[[key]].vue?macro=true";
import { default as paypalcompetitiondCQKUaNIxQMeta } from "/opt/build/repo/pages/paypalcompetition.vue?macro=true";
import { default as postDmud5c8PYEMeta } from "/opt/build/repo/pages/post.vue?macro=true";
import { default as privacyjCuzG6sq6pMeta } from "/opt/build/repo/pages/privacy.vue?macro=true";
import { default as _91id_93qkxQDDw0xUMeta } from "/opt/build/repo/pages/profile/[id].vue?macro=true";
import { default as promoteHp0oYbmJXVMeta } from "/opt/build/repo/pages/promote.vue?macro=true";
import { default as _91key_93i5HLpWVEHjMeta } from "/opt/build/repo/pages/settings/confirmmail/[key].vue?macro=true";
import { default as indexoWr0YesFbSMeta } from "/opt/build/repo/pages/settings/index.vue?macro=true";
import { default as _91id_93ZsZLaxbYQeMeta } from "/opt/build/repo/pages/shortlink/[id].vue?macro=true";
import { default as _91id_93GBmmpiNallMeta } from "/opt/build/repo/pages/shortlinks/[id].vue?macro=true";
import { default as indexBBl6OCnZ9nMeta } from "/opt/build/repo/pages/shortlinks/index.vue?macro=true";
import { default as _91_91groupname_93_93UKhA9Cj7q7Meta } from "/opt/build/repo/pages/stats/[[groupname]].vue?macro=true";
import { default as authoritiesueSHdfhf8jMeta } from "/opt/build/repo/pages/stats/authorities.vue?macro=true";
import { default as _91_91id_93_93LH8PbgDYtPMeta } from "/opt/build/repo/pages/stats/authority/[[id]].vue?macro=true";
import { default as heatmapJOjqH3HhWPMeta } from "/opt/build/repo/pages/stats/heatmap.vue?macro=true";
import { default as _91_91groupid_93_93biS8ZFsOpyMeta } from "/opt/build/repo/pages/stories/[[groupid]].vue?macro=true";
import { default as _91id_93kG4JhMRcXMMeta } from "/opt/build/repo/pages/stories/authority/[id].vue?macro=true";
import { default as summaryuNiNQiUpPOMeta } from "/opt/build/repo/pages/stories/summary.vue?macro=true";
import { default as _91id_933Y8oB8nXaCMeta } from "/opt/build/repo/pages/story/[id].vue?macro=true";
import { default as stripedonateXQ1FdHAz7LMeta } from "/opt/build/repo/pages/stripedonate.vue?macro=true";
import { default as terms9FLT7o4CI4Meta } from "/opt/build/repo/pages/terms.vue?macro=true";
import { default as _91_91id_93_93pqackFhTKsMeta } from "/opt/build/repo/pages/unsubscribe/[[id]].vue?macro=true";
import { default as unsubscribedtxS6ngdPnqMeta } from "/opt/build/repo/pages/unsubscribe/unsubscribed.vue?macro=true";
import { default as _91_91id_93_93ZTmAUhyLbhMeta } from "/opt/build/repo/pages/volunteering/[[id]].vue?macro=true";
import { default as _91_91groupid_93_93CP4OOuv84tMeta } from "/opt/build/repo/pages/volunteerings/[[groupid]].vue?macro=true";
import { default as yahoologinttqmpzN1hqMeta } from "/opt/build/repo/pages/yahoologin.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/opt/build/repo/pages/about.vue")
  },
  {
    name: "adsoff",
    path: "/adsoff",
    component: () => import("/opt/build/repo/pages/adsoff.vue")
  },
  {
    name: "adtest",
    path: "/adtest",
    component: () => import("/opt/build/repo/pages/adtest.vue")
  },
  {
    name: "adtest2",
    path: "/adtest2",
    component: () => import("/opt/build/repo/pages/adtest2.vue")
  },
  {
    name: "browse-term",
    path: "/browse/:term?",
    meta: _91_91term_93_937oyaXUrpA5Meta || {},
    alias: ["/communities"],
    component: () => import("/opt/build/repo/pages/browse/[[term]].vue")
  },
  {
    name: "chats-id",
    path: "/chats/:id?",
    meta: _91_91id_93_93baAmgZz4KTMeta || {},
    component: () => import("/opt/build/repo/pages/chats/[[id]].vue")
  },
  {
    name: "chitchat-id",
    path: "/chitchat/:id?",
    meta: _91_91id_93_93cjIQB5RWmuMeta || {},
    component: () => import("/opt/build/repo/pages/chitchat/[[id]].vue")
  },
  {
    name: "communityevent-id",
    path: "/communityevent/:id?",
    component: () => import("/opt/build/repo/pages/communityevent/[[id]].vue")
  },
  {
    name: "communityevents-groupid",
    path: "/communityevents/:groupid?",
    component: () => import("/opt/build/repo/pages/communityevents/[[groupid]].vue")
  },
  {
    name: "disclaimer",
    path: "/disclaimer",
    component: () => import("/opt/build/repo/pages/disclaimer.vue")
  },
  {
    name: "donate",
    path: "/donate",
    component: () => import("/opt/build/repo/pages/donate.vue")
  },
  {
    name: "donated",
    path: "/donated",
    component: () => import("/opt/build/repo/pages/donated.vue")
  },
  {
    name: "engage",
    path: "/engage",
    component: () => import("/opt/build/repo/pages/engage.vue")
  },
  {
    name: "essex",
    path: "/essex",
    component: () => import("/opt/build/repo/pages/essex.vue")
  },
  {
    name: "explore-id",
    path: "/explore/:id?",
    component: () => import("/opt/build/repo/pages/explore/[[id]].vue")
  },
  {
    name: "explore-groupid-msgid",
    path: "/explore/:groupid()/:msgid?",
    component: () => import("/opt/build/repo/pages/explore/[groupid]/[[msgid]].vue")
  },
  {
    name: "explore-join-id",
    path: "/explore/join/:id?",
    meta: _91_91id_93_93q7kcga9opuMeta || {},
    component: () => import("/opt/build/repo/pages/explore/join/[[id]].vue")
  },
  {
    name: "explore-place-place",
    path: "/explore/place/:place()",
    component: () => import("/opt/build/repo/pages/explore/place/[place].vue")
  },
  {
    name: "explore-region-region",
    path: "/explore/region/:region()",
    component: () => import("/opt/build/repo/pages/explore/region/[region].vue")
  },
  {
    name: "find",
    path: "/find",
    component: () => import("/opt/build/repo/pages/find/index.vue")
  },
  {
    name: "find-whereami",
    path: "/find/whereami",
    component: () => import("/opt/build/repo/pages/find/whereami.vue")
  },
  {
    name: "find-whoami",
    path: "/find/whoami",
    component: () => import("/opt/build/repo/pages/find/whoami.vue")
  },
  {
    name: "forgot",
    path: "/forgot",
    component: () => import("/opt/build/repo/pages/forgot.vue")
  },
  {
    name: "giftaid",
    path: "/giftaid",
    meta: giftaidTgb9chhRCmMeta || {},
    component: () => import("/opt/build/repo/pages/giftaid.vue")
  },
  {
    name: "give",
    path: "/give",
    component: () => import("/opt/build/repo/pages/give/index.vue")
  },
  {
    name: "give-whereami",
    path: "/give/whereami",
    component: () => import("/opt/build/repo/pages/give/whereami.vue")
  },
  {
    name: "give-whoami",
    path: "/give/whoami",
    component: () => import("/opt/build/repo/pages/give/whoami.vue")
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/opt/build/repo/pages/help.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "job-id",
    path: "/job/:id()",
    meta: _91id_93chQVASeUkCMeta || {},
    component: () => import("/opt/build/repo/pages/job/[id].vue")
  },
  {
    name: "jobs",
    path: "/jobs",
    component: () => import("/opt/build/repo/pages/jobs.vue")
  },
  {
    name: "JustGivingThankYou",
    path: "/JustGivingThankYou",
    meta: JustGivingThankYoutNZuSM5CWIMeta || {},
    component: () => import("/opt/build/repo/pages/JustGivingThankYou.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/opt/build/repo/pages/maintenance.vue")
  },
  {
    name: "merge",
    path: "/merge",
    component: () => import("/opt/build/repo/pages/merge.vue")
  },
  {
    name: "message-id",
    path: "/message/:id()",
    component: () => import("/opt/build/repo/pages/message/[id].vue")
  },
  {
    name: "microvolunteering",
    path: "/microvolunteering",
    meta: index_46client2MnN9RLjEeMeta || {},
    component: () => createClientPage(() => import("/opt/build/repo/pages/microvolunteering/index.client.vue"))
  },
  {
    name: "microvolunteering-message-id",
    path: "/microvolunteering/message/:id?",
    meta: _91_91id_93_93_46client1TT3cEYGDQMeta || {},
    component: () => createClientPage(() => import("/opt/build/repo/pages/microvolunteering/message/[[id]].client.vue"))
  },
  {
    name: "mobile",
    path: "/mobile",
    component: () => import("/opt/build/repo/pages/mobile.vue")
  },
  {
    name: "mydata",
    path: "/mydata",
    component: () => import("/opt/build/repo/pages/mydata.vue")
  },
  {
    name: "mypost-id-action",
    path: "/mypost/:id()/:action?",
    meta: _91_91action_93_93xf0GTG8bzGMeta || {},
    component: () => import("/opt/build/repo/pages/mypost/[id]/[[action]].vue")
  },
  {
    name: "myposts",
    path: "/myposts",
    meta: mypostsy8L0HsC1jEMeta || {},
    component: () => import("/opt/build/repo/pages/myposts.vue")
  },
  {
    name: "NationalReuseDay",
    path: "/NationalReuseDay",
    meta: NationalReuseDayCXQOusct1GMeta || {},
    component: () => import("/opt/build/repo/pages/NationalReuseDay.vue")
  },
  {
    name: "noticeboards-id",
    path: "/noticeboards/:id?",
    component: () => import("/opt/build/repo/pages/noticeboards/[[id]].vue")
  },
  {
    name: "noticeboards-authority-id",
    path: "/noticeboards/authority/:id?",
    component: () => import("/opt/build/repo/pages/noticeboards/authority/[[id]].vue")
  },
  {
    name: "one-click-unsubscribe-uid-key",
    path: "/one-click-unsubscribe/:uid?/:key?",
    component: () => import("/opt/build/repo/pages/one-click-unsubscribe/[[uid]]/[[key]].vue")
  },
  {
    name: "paypalcompetition",
    path: "/paypalcompetition",
    component: () => import("/opt/build/repo/pages/paypalcompetition.vue")
  },
  {
    name: "post",
    path: "/post",
    component: () => import("/opt/build/repo/pages/post.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/opt/build/repo/pages/privacy.vue")
  },
  {
    name: "profile-id",
    path: "/profile/:id()",
    component: () => import("/opt/build/repo/pages/profile/[id].vue")
  },
  {
    name: "promote",
    path: "/promote",
    meta: promoteHp0oYbmJXVMeta || {},
    component: () => import("/opt/build/repo/pages/promote.vue")
  },
  {
    name: "settings-confirmmail-key",
    path: "/settings/confirmmail/:key()",
    meta: _91key_93i5HLpWVEHjMeta || {},
    component: () => import("/opt/build/repo/pages/settings/confirmmail/[key].vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: indexoWr0YesFbSMeta || {},
    component: () => import("/opt/build/repo/pages/settings/index.vue")
  },
  {
    name: "shortlink-id",
    path: "/shortlink/:id()",
    component: () => import("/opt/build/repo/pages/shortlink/[id].vue")
  },
  {
    name: "shortlinks-id",
    path: "/shortlinks/:id()",
    component: () => import("/opt/build/repo/pages/shortlinks/[id].vue")
  },
  {
    name: "shortlinks",
    path: "/shortlinks",
    meta: indexBBl6OCnZ9nMeta || {},
    component: () => import("/opt/build/repo/pages/shortlinks/index.vue")
  },
  {
    name: "stats-groupname",
    path: "/stats/:groupname?",
    component: () => import("/opt/build/repo/pages/stats/[[groupname]].vue")
  },
  {
    name: "stats-authorities",
    path: "/stats/authorities",
    component: () => import("/opt/build/repo/pages/stats/authorities.vue")
  },
  {
    name: "stats-authority-id",
    path: "/stats/authority/:id?",
    component: () => import("/opt/build/repo/pages/stats/authority/[[id]].vue")
  },
  {
    name: "stats-heatmap",
    path: "/stats/heatmap",
    component: () => import("/opt/build/repo/pages/stats/heatmap.vue")
  },
  {
    name: "stories-groupid",
    path: "/stories/:groupid?",
    component: () => import("/opt/build/repo/pages/stories/[[groupid]].vue")
  },
  {
    name: "stories-authority-id",
    path: "/stories/authority/:id()",
    component: () => import("/opt/build/repo/pages/stories/authority/[id].vue")
  },
  {
    name: "stories-summary",
    path: "/stories/summary",
    component: () => import("/opt/build/repo/pages/stories/summary.vue")
  },
  {
    name: "story-id",
    path: "/story/:id()",
    component: () => import("/opt/build/repo/pages/story/[id].vue")
  },
  {
    name: "stripedonate",
    path: "/stripedonate",
    component: () => import("/opt/build/repo/pages/stripedonate.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/opt/build/repo/pages/terms.vue")
  },
  {
    name: "unsubscribe-id",
    path: "/unsubscribe/:id?",
    component: () => import("/opt/build/repo/pages/unsubscribe/[[id]].vue")
  },
  {
    name: "unsubscribe-unsubscribed",
    path: "/unsubscribe/unsubscribed",
    component: () => import("/opt/build/repo/pages/unsubscribe/unsubscribed.vue")
  },
  {
    name: "volunteering-id",
    path: "/volunteering/:id?",
    component: () => import("/opt/build/repo/pages/volunteering/[[id]].vue")
  },
  {
    name: "volunteerings-groupid",
    path: "/volunteerings/:groupid?",
    component: () => import("/opt/build/repo/pages/volunteerings/[[groupid]].vue")
  },
  {
    name: "yahoologin",
    path: "/yahoologin",
    meta: yahoologinttqmpzN1hqMeta || {},
    component: () => import("/opt/build/repo/pages/yahoologin.vue")
  }
]